$hyoo_habhub $mol_book2
	plugins /
		<= Theme $mol_theme_auto
		<= Search_start $mol_hotkey
			key * F? <=> search_start? null
			mod_ctrl true
	Menu_page $mol_page
		title <= menu_title \HabHub
		tools <= tools_root /
			<= Add $mol_link
				hint \Add article
				uri \https://github.com/nin-jin/habhub
				sub /
					<= Add_icon $mol_icon_plus
			<= Source_link $mol_link_source
				uri \https://github.com/hyoo-ru/habhub.hyoo.ru
			<= Lights $mol_lights_toggle
		body /
			<= Search $mol_search_jumper
				query?val <=> search?val \
				Root <= Details_body $mol_view
			<= Menu $mol_list
				rows <= menu_rows /
	Details!id $mol_page
		title <= gist_current_title \
		tools /
			<= Details_chat!id $mol_chat
				seed <= chat_seed!id \
				pages => chat_pages!id
			<= Created $mol_paragraph
				title <= gist_current_created \
			<= Details_link $mol_link_source
				uri <= details_link \
			<= Close $mol_link
				hint \Close article
				arg <= close_arg *
					author null
					repo null
					article null
					gist null
				sub /
					<= Close_icon $mol_icon_close
		body_scroll_top?val <=> details_scroll_top?val 0
		body /
			<= Datails_text $mol_text
				highlight <= search
				text <= gist_current_content \
	Menu_row!id $mol_link
		minimal_height 40
		sub /
			<= Menu_row_title!id $mol_dimmer
				needle <= search
				haystack <= gist_title!id \
		arg <= gist_arg!id *
